import OT, { Device } from '@opentok/client';
import { EventEmitter } from 'events';
import { throttle } from 'lodash';
import { logger } from '../logging';
import getDeviceId from '../mp/get-device-id';
import { VideoOverlay, createPublisherOverlay } from './video-overlay/overlay';
import iconSmileyFace from '../assets/icons/smile';
import { VideoFilter } from '../utils/types';

const _attachEventsToOTPublisher = (
  self: Publisher,
  publisher: OT.Publisher
): void => {
  const _onAudioLevelUpdated = (event: { audioLevel: number }) => {
    self.emit('audioLevelUpdated', event.audioLevel);
  };

  const audioLevelUpdatedHandler = throttle(_onAudioLevelUpdated, 60, {
    // So we don't send events after stream is destroyed
    leading: true,
    trailing: false,
  });

  publisher.on('destroyed', () => self.onDestroyed());

  publisher.on('videoElementCreated', self._onVideoElementCreated);

  if (self.source === 'camera') {
    // audioLevelUpdated event will throw an exception for screen publisher,
    // if the screen being published is not a chrome tab with "share audio" selected
    // @TODO: Need to add checks for this scenario here
    publisher.on('audioLevelUpdated', audioLevelUpdatedHandler);

    publisher.on('accessAllowed', () => {
      self.emit('accessAllowed');
    });

    publisher.on('accessDenied', () => {
      self.emit('accessDenied');
    });

    publisher.on('accessDialogOpened', () => {
      self.emit('accessDialogOpened');
    });

    publisher.on('accessDialogClosed', reason => {
      self.emit('accessDialogClosed', reason);
    });
  }
};

const _initPublisherForCamera = (
  targetElement: HTMLElement | string | undefined,
  options: OT.PublisherProperties | undefined,
  publisher: Publisher
): Promise<OT.Publisher> =>
  new Promise((resolve, reject) => {
    const _otPublisher = OT.initPublisher(
      targetElement,
      {
        ...options,
        insertMode: 'append',
        style: {
          buttonDisplayMode: 'off',
          nameDisplayMode: 'off',
          backgroundImageURI:
            options?.style?.backgroundImageURI || iconSmileyFace,
        },
      },
      error => {
        if (error) {
          reject(error);
        } else {
          resolve(_otPublisher);
        }
      }
    );
    // We must attach events here. Doing so in callback or after resolving is too late
    if (_otPublisher) {
      publisher.otPublisher = _otPublisher;
      _attachEventsToOTPublisher(publisher, _otPublisher);
    }
  });

const _initPublisherForScreen = (
  targetElement: HTMLElement | string | undefined,
  options: OT.PublisherProperties | undefined,
  publisher: Publisher
): Promise<OT.Publisher> =>
  new Promise((resolve, reject) => {
    OT.checkScreenSharingCapability(response => {
      if (!response.supported || response.extensionRegistered === false) {
        reject('Screen sharing not supported for this browser');
      } else {
        const _otPublisher = OT.initPublisher(
          targetElement,
          {
            videoSource: 'screen',
            insertMode: 'append',
            showControls: false,
            videoContentHint: 'detail',
            ...options,
          },
          error => {
            if (error) {
              reject(error);
            } else {
              resolve(_otPublisher);
            }
          }
        );
        // We must attach events here. Doing so in callback or after resolving is too late
        _attachEventsToOTPublisher(publisher, _otPublisher);
      }
    });
  });

declare interface Publisher {
  on(event: 'audioLevelUpdated', listener: (audioLevel: number) => void): this;
  on(event: 'videoElementCreated', listener: () => void): this;
  on(event: 'destroyed', listener: () => void): this;
  on(event: 'accessAllowed', listener: () => void): this;
  on(event: 'accessDenied', listener: () => void): this;
  on(event: 'accessDialogOpened', listener: () => void): this;
  on(event: 'accessDialogClosed', listener: (reason: string) => void): this;
}

class Publisher extends EventEmitter {
  otPublisher?: OT.Publisher;
  source: string;
  name?: string;
  _otPublisherPromise?: Promise<OT.Publisher>;
  overlay: VideoOverlay | undefined;

  constructor(source: string = 'camera', name?: string) {
    super();
    this.source = source;
    this.name = name;
  }

  initOTPublisher = async (
    targetElement?: HTMLElement | string,
    publisherOptions?: OT.PublisherProperties
  ): Promise<OT.Publisher> => {
    if (!this._otPublisherPromise) {
      let initPublisher = _initPublisherForCamera;
      if (this.source === 'screen') {
        initPublisher = _initPublisherForScreen;
      }
      this._otPublisherPromise = initPublisher(
        targetElement,
        publisherOptions,
        this
      );
      let otPublisher: OT.Publisher;
      try {
        otPublisher = await this._otPublisherPromise;
      } catch (e) {
        this._otPublisherPromise = undefined;
        throw e;
      }
      this.otPublisher = otPublisher;
    }
    return await this._otPublisherPromise;
  };

  getOTPublisher = (): Promise<OT.Publisher> | undefined =>
    this._otPublisherPromise;

  onDestroyed = () => {
    logger.debug('Publisher destroyed');
    this._otPublisherPromise = undefined;
    this.emit('destroyed');
  };

  _onVideoElementCreated = () => {
    if (this.source === 'camera') {
      const divId = this.otPublisher?.id;
      if (divId) {
        this.overlay = createPublisherOverlay({
          id: divId,
          isAudioEnabled: this.isAudioEnabled(),
          participantName: this.name,
        });
        this.overlay?.on('toggleAudioClicked', () => {
          this._toggleAudioEnabled();
        });
      } else {
        logger.warn(
          'Could not find stream id when trying to create camera publisher overlay'
        );
      }
    }
    this.emit('videoElementCreated');
  };

  _toggleAudioEnabled = () => {
    if (this.isAudioEnabled()) {
      this.disableAudio();
    } else {
      this.enableAudio();
    }
  };
  isVideoEnabled = (): boolean => !!this.otPublisher?.getVideoSource()?.track;

  isAudioEnabled = (): boolean => !!this.otPublisher?.getAudioSource()?.enabled;

  enableVideo = (): void => this.otPublisher?.publishVideo(true);

  disableVideo = (): void => this.otPublisher?.publishVideo(false);

  enableAudio = (): void => {
    this.otPublisher?.publishAudio(true);
    this.overlay?.setAudioEnabled();
  };

  disableAudio = (): void => {
    this.otPublisher?.publishAudio(false);
    this.overlay?.setAudioDisabled();
  };

  getVideoDevice = (): Device => {
    const videoDevice: Device = { deviceId: '', label: '', kind: 'videoInput' };
    if (this.otPublisher) {
      const { deviceId, track } = this.otPublisher.getVideoSource();
      videoDevice.deviceId = deviceId || '';
      videoDevice.label = track?.label || '';
    }
    return videoDevice;
  };

  setVideoDevice = async (videoDeviceId: string): Promise<void> =>
    await this.otPublisher?.setVideoSource(videoDeviceId);

  getAudioDevice = async (): Promise<Device> => {
    const track = this.otPublisher?.getAudioSource();
    const deviceId = track ? await getDeviceId(track) : '';
    return {
      deviceId,
      label: track?.label || '',
      kind: 'audioInput',
    };
  };

  setAudioDevice = async (audioDeviceId: string): Promise<void> =>
    await this.otPublisher?.setAudioSource(audioDeviceId);

  destroyOTPublisher = (): void => this.otPublisher?.destroy();

  setVideoFilter = async (videoFilter: VideoFilter): Promise<void> =>
    await this.otPublisher?.applyVideoFilter(videoFilter);

  clearVideoFilter = async (): Promise<void> =>
    await this.otPublisher?.clearVideoFilter();

  setDisabledImageURI = (imageURI: string): void => {
    this.otPublisher?.setStyle('backgroundImageURI', imageURI);
  };
}

export default Publisher;
