import OT from '@opentok/client';
import { Analytics, logAction, logVariation } from '../analytics';
import { logger } from '../logging';

const setAudioOutputDevice = async (deviceId: string): Promise<void> => {
  const analytics = new Analytics();
  analytics.log(logAction.setAudioOutputDevice, logVariation.attempt);
  try {
    logger.info('Set audio output device: ', deviceId);
    await OT.setAudioOutputDevice(deviceId);
    analytics.log(logAction.setAudioOutputDevice, logVariation.success);
  } catch (error) {
    analytics.log(logAction.setAudioOutputDevice, logVariation.failure);
    logger.warn('Not able to set the audio output device', deviceId);
    throw new Error('Not able to set the audio output device');
  }
};

export default setAudioOutputDevice;
