import OT, { AudioOutputDevice } from '@opentok/client';
import { Analytics, logAction, logVariation } from '../analytics';

const analytics = new Analytics();

const getActiveAudioOutputDevice = async (): Promise<AudioOutputDevice> => {
  analytics.log(logAction.getActiveAudioOutputDevice, logVariation.attempt);
  try {
    const activeDevice = await OT.getActiveAudioOutputDevice();
    analytics.log(logAction.getActiveAudioOutputDevice, logVariation.success);
    return activeDevice;
  } catch (error) {
    analytics.log(logAction.getActiveAudioOutputDevice, logVariation.failure);
    throw new Error('Not able to get active audio output device');
  }
};

export default getActiveAudioOutputDevice;
