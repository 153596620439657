import { EventEmitter } from 'events';
import Participant from '../internal/participant';
import CameraSubscriber from '../mp/camera-subscriber';
import ScreenSubscriber from '../mp/screen-subscriber';

declare interface ParticipantWrapper {
  on(
    event: 'cameraCreated',
    listener: (cameraSubscriber: CameraSubscriber) => void
  ): this;
  on(
    event: 'screenCreated',
    listener: (cameraSubscriber: ScreenSubscriber) => void
  ): this;
  on(event: 'cameraDestroyed', listener: () => void): this;
  on(event: 'screenDestroyed', listener: () => void): this;
}

class ParticipantWrapper extends EventEmitter {
  id: string;
  camera?: CameraSubscriber;
  screen?: ScreenSubscriber;
  name?: string;
  connectionData: string;

  constructor(participant: Participant) {
    super();
    this.id = participant.id;
    this.connectionData = participant.connectionData;

    /*
     * Here we use defineProperty to set a getting because participant.screen
     * and participant.camera are initially undefined and setting e.g.:
     * this.camera = participant.camera would not work when the object reference changes.
     */
    Object.defineProperty(this, 'camera', { get: () => participant.camera });
    Object.defineProperty(this, 'screen', { get: () => participant.screen });
    Object.defineProperty(this, 'name', {
      get: () => participant.name,
    });

    participant.on('cameraCreated', cameraSubscriber => {
      this.emit('cameraCreated', cameraSubscriber);
    });

    participant.on('cameraDestroyed', () => {
      this.emit('cameraDestroyed');
    });

    participant.on('screenCreated', screenSubscriber => {
      this.emit('screenCreated', screenSubscriber);
    });

    participant.on('screenDestroyed', () => {
      this.emit('screenDestroyed');
    });
  }
}

export default ParticipantWrapper;
