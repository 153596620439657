import OT, { AudioOutputDevice } from '@opentok/client';
import { Analytics, logAction, logVariation } from '../analytics';

const analytics = new Analytics();

const getAudioOutputDevices = async (): Promise<AudioOutputDevice[]> => {
  analytics.log(logAction.getAudioOutputDevices, logVariation.attempt);
  try {
    const devices = await OT.getAudioOutputDevices();
    analytics.log(logAction.getAudioOutputDevices, logVariation.success);
    return devices;
  } catch (error) {
    analytics.log(logAction.getAudioOutputDevices, logVariation.failure);
    throw new Error('Not able to get available audio output device');
  }
};

export default getAudioOutputDevices;
