import { Connection } from '@opentok/client';
import { EventEmitter } from 'events';
import CameraSubscriber from '../mp/camera-subscriber';
import ScreenSubscriber from '../mp/screen-subscriber';

declare interface Participant {
  on(
    event: 'cameraCreated',
    listener: (cameraSubscriber: CameraSubscriber) => void
  ): this;
  on(
    event: 'screenCreated',
    listener: (cameraSubscriber: ScreenSubscriber) => void
  ): this;
  on(event: 'cameraDestroyed', listener: () => void): this;
  on(event: 'screenDestroyed', listener: () => void): this;
}
class Participant extends EventEmitter {
  id: string;
  connection: Connection;
  camera?: CameraSubscriber;
  screen?: ScreenSubscriber;
  name: string | null = null;
  isParticipantJoinedSent: boolean = false;
  connectionData: string;

  constructor(connection: Connection) {
    super();
    this.connection = connection;
    const { connectionId, data } = connection;
    this.id = connectionId;
    this.connectionData = data;
  }

  setCameraSubscriber(cameraSubscriber: CameraSubscriber) {
    this.camera = cameraSubscriber;
    cameraSubscriber.on('created', () => {
      this.emit('cameraCreated', cameraSubscriber);
    });
    cameraSubscriber.on('destroyed', () => {
      this.emit('cameraDestroyed');
    });
  }

  setScreenSubscriber(screenSubscriber: ScreenSubscriber) {
    this.screen = screenSubscriber;
    screenSubscriber.on('created', () => {
      this.emit('screenCreated');
    });
    screenSubscriber.on('destroyed', () => {
      this.emit('screenDestroyed');
    });
  }
}

export default Participant;
